import React from 'react';

import SEO from '../../components/seo';
import Navigation from '../../components/navigation';
import Discover from '../../components/discover';
import VerticalCenteredSection from '../../components/vertical-centered-section';

import { graphql } from 'gatsby';
import { Languages } from '../../tools/languages';
import ATillZ from '../../components/a-till-z-cta';

const EnglishSupportPage = ({ location, data, language = Languages.EN }) => {
  const pathName = location.pathname;

  return (
    <>
      <SEO
        pathName={pathName}
        title={data.support.SEO.title}
        description={data.support.SEO.description}
      />
      <Navigation />
      <main>
        <h1>{data.support.SEO.title}</h1>
        {data.support.items.map((item, index) => {
          const { id, image, label, title, text, links, supportItems } = item;
          return (
            <VerticalCenteredSection
              key={id}
              index={index}
              isFirst={index === 0}
              isLast={index === data.support.items.length - 1}
              image={image.childImageSharp.fluid.src}
              label={label}
              title={title}
              text={text}
              links={links}
              supportItems={supportItems}
            />
          );
        })}
        <hr />
        <ATillZ language={language} />
        <Discover dark={true} language={language} />
      </main>
    </>
  );
};

export const query = graphql`
  {
    support: strapiSupportpageEn {
      SEO {
        title
        description
      }
      items: SupportItem {
        id
        image {
          childImageSharp {
            fluid(quality: 100) {
              src
            }
          }
        }
        title
        text
        label
        links: SupportItemLink {
          name
          destination
        }
        supportItems: support_items {
          title: title_en
          slug
        }
      }
    }
  }
`;

export default EnglishSupportPage;
